
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';
import CmsContent from '@/components/CmsContent.vue';
import HowItWorksPartial from '@/components/HowItWorksPartial.vue';
import FloodTrackerLogo from '@/components/logo/FloodTracker.vue';
import HeatTrackerLogo from '@/components/logo/HeatTracker.vue';
import RouteNames from '@/router/names';
import { CurrentUser, Post } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        AppDownloadLinks,
        CmsContent,
        FloodTrackerLogo,
        HeatTrackerLogo,
        HowItWorksPartial,
    },

    i18n: {
        messages: {
            en: {
                signUpOrIn: 'Sign up or log in',
                heroAlt: 'Photos of weather situations with overlayed data points',
                trackerHeading: 'Quickly report about extreme heat or flooding, no app required!',
                trackerBody: 'Our Tracker tools empower communities to respond to extreme weather.',
                ctaHeat: 'Report Heat',
                ctaFlooding: 'Report Flooding',
                trackerAlt: 'Screens showing the HeatTracker and FloodTracker UIs',
            },

            es: {
                signUpOrIn: 'Regístrese o inicie sesión',
                heroAlt: 'Fotos de situaciones meteorológicas con puntos de datos superpuestos',
                trackerHeading: 'Informe rápidamente sobre calor extremo o inundaciones, ¡no se requiere ninguna aplicación!',
                trackerBody: 'Nuestras herramientas de rastreador capacitan a las comunidades para responder al clima extremo.',
                ctaHeat: 'Informar calor',
                ctaFlooding: 'Informar inundaciones',
                trackerAlt: 'Pantallas que muestran el HeatTracker y UIS de inundación',
            },
        },
    },

    computed: {
        RouteNames(): typeof RouteNames {
            return RouteNames;
        },

        currentUser(): CurrentUser | null {
                return this.$store.state.account.currentUser;
        },

        specialLink() {
            return {
                image: 'https://images.prismic.io/iseechange/ZwQjZIF3NbkBW_Bg_Milton_2024-10-07_1621Z.jpg?auto=format,compress&height=640',
                href: 'https://iseechange.notion.site/Hurricane-Milton-Storm-Resources-118d7951f7b28059b34ad38bc8ab2956',
                heading: 'Hurricane Milton',
                title: 'Check out our continuously updated resource hub as Hurricane Milton heads toward Florida',
                subtitle: 'Storm resources →',
            };
        },

        recentPosts(): Post[] {
            return this.$store.state.posts.mostRecent
                .filter(post => post.media.find(media => media.mediaType === 'image'))
                .slice(0, this.specialLink ? 2 : 3);
        },

        recentStoryImage(): string {
            // @ts-ignore
            const extDotIndex = this.recentStory.image.lastIndexOf('.');
            // @ts-ignore
            const path = this.recentStory.image.slice(0, extDotIndex);
            // @ts-ignore
            const ext = this.recentStory.image.slice(extDotIndex + 1);
            return `${path}-800x425.${ext}`;
        }
    },

    async mounted() {
        this.$store.dispatch('fetchMostRecentPosts');
        this.$store.dispatch('fetchStories');
    },
});
